import { Breadcrumbs, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Tooltip, Typography } from '@mui/material';
import { Box, Grid } from '@mui/system';
import * as React from 'react';
import { StyledBreadcrumb } from '../../components/StyledBreadcrumb';
import { useSelector } from 'react-redux';
import { RootState } from '../../shared/reducers/store';
import { Link, useLocation } from 'react-router-dom';
import { Drawer } from '../../components/Drawer';
import ActionAreaCard from '../../components/ActionAreaCard';
import { useLogic } from '../../providers/LogicProvider';
import { Pipeline } from '../../shared/libs/pipeline';


export default function PrivatePage() {
    const location = useLocation()
    const [selectedIndex, setSelectedIndex] = React.useState<string>(location.pathname);
    const openMenu = useSelector((state: RootState) => state.openMenu.value);
    const user = useSelector((state: RootState) => state.user.value);
    const [listMenu, setListMenu] = React.useState([])
    const [accessToken, setAccessToken] = React.useState<any>("")
    const { logic } = useLogic()
    const pipeline = new Pipeline()

    async function init() {

        const value = await pipeline.get()
        setListMenu(value?.apps.main)

        logic?.token().new().then((newToken:any) => {
            setAccessToken(newToken)
        })
    }

    React.useEffect(() => {
        init()

    }, [])
    return (<>
        <Box sx={{ display: 'flex' }}>
            <Drawer variant="permanent" open={openMenu}>
                <Toolbar />
                <List sx={{ display: 'block' }}>
                    {[{
                        id: "home", appName: "Home", link: "/", icon: {
                            type: "awesome",
                            value: "house"
                        }
                    }
                    ].map((val, index) => (
                        <ListItem key={val.id} disablePadding >
                            {openMenu ? (<>
                                <ListItemButton
                                    selected={selectedIndex === `${val.link}`}
                                    component={Link}
                                    to={`${val.link}`}
                                    onClick={(event) => {
                                        setSelectedIndex(`${val.link}`)
                                    }}
                                >
                                    <ListItemIcon>
                                        {val.icon.type === "awesome" && (<i className={`fa-duotone fa-solid fa-${val.icon.value}`}></i>)}
                                    </ListItemIcon>
                                    <ListItemText primary={val.appName} />
                                </ListItemButton>
                            </>) : (<Tooltip title={val.appName} placement={"left"}>
                                <ListItemButton
                                    selected={selectedIndex === `${val.link}`}
                                    component={Link}
                                    to={`${val.link}`}
                                    onClick={(event) => {
                                        setSelectedIndex(`${val.link}`)
                                    }}
                                >
                                    <ListItemIcon>
                                        {val.icon.type === "awesome" && (<i className={`fa-duotone fa-solid fa-${val.icon.value}`}></i>)}
                                    </ListItemIcon>
                                    <ListItemText primary={val.appName} />
                                </ListItemButton>
                            </Tooltip>)}

                        </ListItem>
                    ))}
                    {listMenu.map((val: any, index) => (
                        <ListItem key={val.id} disablePadding >
                            {openMenu ? (<>
                                <ListItemButton
                                    selected={selectedIndex === `${val.link}`}
                                    // component={Link}
                                    href={`${val.link}?token=${accessToken}`}

                                    onClick={(event) => {
                                        setSelectedIndex(`${val.link}`)
                                    }}
                                >
                                    <ListItemIcon>
                                        {val.icon.type === "awesome" && (<i className={`fa-duotone fa-solid fa-${val.icon.value}`}></i>)}

                                    </ListItemIcon>
                                    <ListItemText primary={val.appName} />
                                </ListItemButton>
                            </>) : (<Tooltip title={val.appName} placement={"left"}>
                                <ListItemButton
                                    selected={selectedIndex === `${val.link}`}
                                    // component={Link}
                                    href={`${val.link}`}
                                    onClick={(event) => {
                                        setSelectedIndex(`${val.link}`)
                                    }}
                                >
                                    <ListItemIcon>
                                        {val.icon.type === "awesome" && (<i className={`fa-duotone fa-solid fa-${val.icon.value}`}></i>)}
                                    </ListItemIcon>
                                    <ListItemText primary={val.appName} />
                                </ListItemButton>
                            </Tooltip>)}

                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
                <Toolbar />
                <Breadcrumbs aria-label="breadcrumb">
                    {location.pathname.split('/').slice(2).length > 0 ? (<>
                        {location.pathname.split('/').slice(2).map((val) => (<StyledBreadcrumb
                            key={val}
                            component="a"
                            label={val}
                            icon={<i className={`fa-duotone fa-solid fa-${val}`}></i>}
                        />))}
                    </>) : (<>
                        <StyledBreadcrumb
                            key={"home"}
                            component="a"
                            label={"Home"}
                            icon={<i className={`fa-duotone fa-solid fa-house`}></i>}
                        />
                    </>)}

                </Breadcrumbs>
                <Typography sx={{ flexGrow: 1, p: 3 }}>
                    <Grid container spacing={2}>
                        <Grid size={{ xs: 12 }}>
                            <Typography variant="h6" gutterBottom>แอพของฉัน</Typography>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                    {listMenu.map((val: any, index) => (<ActionAreaCard key={val.id} id={val.id} link={`${val.link}?token=${accessToken}`} appName={val.appName} discription={val.discription} icon={val.icon} ></ActionAreaCard>))}
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Typography>
            </Box>
        </Box >


    </>)
}