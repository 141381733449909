import { AuthProvider, User } from "firebase/auth"
import { Config, GitConfig, GitRepoConfig } from "../../../config"
import { LocalStorage } from "../../libs/local/storage"
import { AppDispatch } from "../../reducers/store"
import { Service } from "../../services"
import git from 'isomorphic-git'

export class AuthLogic {
    dispatch: AppDispatch
    localStorage: LocalStorage
    service: Service
    config: Config
    constructor({ dispatch, localStorage, service, config }: { dispatch: AppDispatch, localStorage: LocalStorage, service: Service, config: Config }) {
        this.dispatch = dispatch
        this.localStorage = localStorage
        this.service = service
        this.config = config
    }

    async register(payload: {
        email: string;
        password: string;
    }) {
        const gitConfig: GitConfig = this.config.git()

        function gitClone(repo: GitRepoConfig) {
            const fs = repo.fs;
            const dir = repo.dir
            const url = repo.url
            const http = gitConfig.http
            const author = gitConfig.author
            const ref = gitConfig.ref
            const corsProxy = gitConfig.corsProxy
            git.listFiles({ fs, dir, ref }).then((listFIles) => {
                if (listFIles) {
                    git.pull({
                        fs,
                        http,
                        dir,
                        author
                    }).then(() => {
                        git.checkout({ fs, dir, ref: `${process.env.NODE_ENV}` }).then(() => {
                            console.log("git checkout register: ", `${process.env.NODE_ENV}`);

                        }).catch(err => {
                            console.log("git checkout register err: ", err);
                            git.branch({ fs, dir, ref: `${process.env.NODE_ENV}` }).then(() => {
                                git.push({ fs, http, dir }).then(() => {
                                    console.log("new branch: ", `${process.env.NODE_ENV}`);

                                })
                            })
                        })
                    })
                }
            }).catch((err) => {
                git.clone({ fs, http, dir, corsProxy, url, singleBranch: false, depth: 1 }).then(() => {
                    git.checkout({ fs, dir, ref: `${process.env.NODE_ENV}` }).then(() => {
                        console.log("git checkout register: ", `${process.env.NODE_ENV}`);

                    }).catch(err => {
                        console.log("git checkout register err: ", err);
                        git.branch({ fs, dir, ref: `${process.env.NODE_ENV}` }).then(() => {
                            git.push({ fs, http, dir }).then(() => {
                                console.log("new branch: ", `${process.env.NODE_ENV}`);

                            })
                        })
                    })
                })
            })
        }

        try {
            const firebase = await this.service.firebase()
            const serviceData = await firebase.auth().email().signUp(payload)
            const github = this.service.github()

            if (serviceData) {
                const { user } = serviceData;
                this.localStorage.collection('uid').set(user.uid)

                const repo: any = await github.org("idev-coder-console").repo(user.uid).create()

                if (repo) {
                    const userRepo = gitConfig.org('idev-coder-console').repo(user.uid)
                    gitClone(userRepo)
                }
                firebase.auth().email().emailVerification(user).then(() => {
                    const message: string = "Email verification sent!"
                    if (message) {

                        this.dispatch({
                            payload: {
                                open: true,
                                severity: "warning",
                                message: `${message}`,
                                vertical: "top",
                                horizontal: "center",
                                variant: "outlined"
                            },
                            type: "alert/useAlertReducer"
                        })
                    }
                })

            }


        } catch (error) {
            this.dispatch({
                payload: {
                    open: true,
                    severity: "error",
                    message: `${error}`,
                    vertical: "top",
                    horizontal: "center",
                    variant: "outlined"
                },
                type: "alert/useAlertReducer"
            })
            return error
        }
    }

    async login(payload: {
        email: string;
        password: string;
    }) {
        const gitConfig: GitConfig = this.config.git()

        function gitClone(repo: GitRepoConfig) {
            const fs = repo.fs;
            const dir = repo.dir
            const url = repo.url
            const http = gitConfig.http
            const author = gitConfig.author
            const ref = gitConfig.ref
            const corsProxy = gitConfig.corsProxy
            git.listFiles({ fs, dir, ref }).then((listFIles) => {
                if (listFIles) {
                    git.pull({
                        fs,
                        http,
                        dir,
                        author
                    }).then(() => {
                        git.checkout({ fs, dir, ref: `${process.env.NODE_ENV}` }).then(() => {
                            console.log("git checkout login: ", `${process.env.NODE_ENV}`);

                        }).catch(err => {
                            console.log("git checkout login err: ", err);
                            git.branch({ fs, dir, ref: `${process.env.NODE_ENV}` }).then(() => {
                                git.push({ fs, http, dir }).then(() => {
                                    console.log("new branch: ", `${process.env.NODE_ENV}`);

                                })
                            })
                        })
                    })
                }
            }).catch((err) => {
                git.clone({ fs, http, dir, corsProxy, url, singleBranch: false, depth: 1 }).then(() => {
                    git.checkout({ fs, dir, ref: `${process.env.NODE_ENV}` }).then(() => {
                        console.log("git checkout login: ", `${process.env.NODE_ENV}`);

                    }).catch(err => {
                        console.log("git checkout login err: ", err);
                        git.branch({ fs, dir, ref: `${process.env.NODE_ENV}` }).then(() => {
                            git.push({ fs, http, dir }).then(() => {
                                console.log("new branch: ", `${process.env.NODE_ENV}`);

                            })
                        })
                    })
                })
            })
        }
        try {

            const firebase = await this.service.firebase()
            const serviceData = await firebase.auth().email().signIn(payload)


            if (serviceData) {
                const { user } = serviceData;
                this.localStorage.collection('uid').set(user.uid)
                this.dispatch({
                    payload: user,
                    type: "user/useUserReducer"
                })
                this.dispatch({ type: "login/useLoginReducer" })

                const userRepo = gitConfig.org('idev-coder-console').repo(user.uid)
                gitClone(userRepo)
                return serviceData
            } else {
                this.dispatch({
                    payload: {
                        open: true,
                        severity: "error",
                        message: `ไม่พบ User`,
                        vertical: "top",
                        horizontal: "center",
                        variant: "outlined"
                    },
                    type: "alert/useAlertReducer"
                })

                return null
            }
        } catch (err) {

            this.dispatch({
                payload: {
                    open: true,
                    severity: "error",
                    message: `E-Mail หรือ Password ไม่ถูกต้อง`,
                    vertical: "top",
                    horizontal: "center",
                    variant: "outlined"
                },
                type: "alert/useAlertReducer"
            })

            return err
        }
    }

    async loginToken(token:string) {
        const gitConfig: GitConfig = this.config.git()

        function gitClone(repo: GitRepoConfig) {
            const fs = repo.fs;
            const dir = repo.dir
            const url = repo.url
            const http = gitConfig.http
            const author = gitConfig.author
            const ref = gitConfig.ref
            const corsProxy = gitConfig.corsProxy
            git.listFiles({ fs, dir, ref }).then((listFIles) => {
                if (listFIles) {
                    git.pull({
                        fs,
                        http,
                        dir,
                        author
                    }).then(() => {
                        git.checkout({ fs, dir, ref: `${process.env.NODE_ENV}` }).then(() => {
                            console.log("git checkout login: ", `${process.env.NODE_ENV}`);

                        }).catch(err => {
                            console.log("git checkout login err: ", err);
                            git.branch({ fs, dir, ref: `${process.env.NODE_ENV}` }).then(() => {
                                git.push({ fs, http, dir }).then(() => {
                                    console.log("new branch: ", `${process.env.NODE_ENV}`);

                                })
                            })
                        })
                    })
                }
            }).catch((err) => {
                git.clone({ fs, http, dir, corsProxy, url, singleBranch: false, depth: 1 }).then(() => {
                    git.checkout({ fs, dir, ref: `${process.env.NODE_ENV}` }).then(() => {
                        console.log("git checkout login: ", `${process.env.NODE_ENV}`);

                    }).catch(err => {
                        console.log("git checkout login err: ", err);
                        git.branch({ fs, dir, ref: `${process.env.NODE_ENV}` }).then(() => {
                            git.push({ fs, http, dir }).then(() => {
                                console.log("new branch: ", `${process.env.NODE_ENV}`);

                            })
                        })
                    })
                })
            })
        }
        try {

            const firebase = await this.service.firebase()
            const serviceData = await firebase.auth().token(token)


            if (serviceData) {
                const { user } = serviceData;
                this.localStorage.collection('uid').set(user.uid)
                this.dispatch({
                    payload: user,
                    type: "user/useUserReducer"
                })
                this.dispatch({ type: "login/useLoginReducer" })

                const userRepo = gitConfig.org('idev-coder-console').repo(user.uid)
                gitClone(userRepo)
                return serviceData
            } else {
                this.dispatch({
                    payload: {
                        open: true,
                        severity: "error",
                        message: `ไม่พบ User`,
                        vertical: "top",
                        horizontal: "center",
                        variant: "outlined"
                    },
                    type: "alert/useAlertReducer"
                })

                return null
            }
        } catch (err) {

            this.dispatch({
                payload: {
                    open: true,
                    severity: "error",
                    message: `E-Mail หรือ Password ไม่ถูกต้อง`,
                    vertical: "top",
                    horizontal: "center",
                    variant: "outlined"
                },
                type: "alert/useAlertReducer"
            })

            return err
        }
    }


    async googleLogin(provider: AuthProvider) {
        const gitConfig: GitConfig = this.config.git()

        function gitClone(repo: GitRepoConfig) {
            const fs = repo.fs;
            const dir = repo.dir
            const url = repo.url
            const http = gitConfig.http
            const author = gitConfig.author
            const ref = gitConfig.ref
            const corsProxy = gitConfig.corsProxy
            git.listFiles({ fs, dir, ref }).then((listFIles) => {
                if (listFIles) {
                    git.pull({
                        fs,
                        http,
                        dir,
                        author
                    }).then(() => {
                        git.checkout({ fs, dir, ref: `${process.env.NODE_ENV}` }).then(() => {
                            console.log("git checkout google login: ", `${process.env.NODE_ENV}`);

                        }).catch(err => {
                            console.log("git checkout google login err: ", err);
                            git.branch({ fs, dir, ref: `${process.env.NODE_ENV}` }).then(() => {
                                git.push({ fs, http, dir }).then(() => {
                                    console.log("new branch: ", `${process.env.NODE_ENV}`);

                                })
                            })
                        })
                    })
                }
            }).catch((err) => {
                git.clone({ fs, http, dir, corsProxy, url, singleBranch: false, depth: 1 }).then(() => {
                    git.checkout({ fs, dir, ref: `${process.env.NODE_ENV}` }).then(() => {
                        console.log("git checkout google login: ", `${process.env.NODE_ENV}`);

                    }).catch(err => {
                        console.log("git checkout google login err: ", err);
                        git.branch({ fs, dir, ref: `${process.env.NODE_ENV}` }).then(() => {
                            git.push({ fs, http, dir }).then(() => {
                                console.log("new branch: ", `${process.env.NODE_ENV}`);

                            })
                        })
                    })
                })
            })
        }
        try {
            const firebase = await this.service.firebase()
            const serviceData = await firebase.auth().google().signIn(provider)
            if (serviceData) {
                const { user } = serviceData;
                this.localStorage.collection('uid').set(user.uid)
                this.dispatch({
                    payload: user,
                    type: "user/useUserReducer"
                })
                this.dispatch({ type: "login/useLoginReducer" })

                const userRepo = gitConfig.org('idev-coder-console').repo(user.uid)
                gitClone(userRepo)
            }
        } catch (err) {
            this.dispatch({
                payload: {
                    open: true,
                    severity: "error",
                    message: `${err}`,
                    vertical: "top",
                    horizontal: "center",
                    variant: "outlined"
                },
                type: "alert/useAlertReducer"
            })
        }
    }

    async logout() {
        const firebase = await this.service.firebase()
        firebase.auth().signOut().then(() => {
            this.dispatch({ type: "login/useLogoutReducer" })
            this.localStorage.clear()
        })


    }

    async emailVerification(user: User) {

        const firebase = await this.service.firebase()
        firebase.auth().email().emailVerification(user).then(() => {
            const message: string = "Email verification sent!"
            this.dispatch({
                payload: {
                    open: true,
                    severity: "success",
                    message: `${message}`,
                    vertical: "top",
                    horizontal: "center",
                    variant: "outlined"
                },
                type: "alert/useAlertReducer"
            })
        })
    }

    async resetPassword(email: string) {

        const firebase = await this.service.firebase()
        firebase.auth().email().resetPassword(email).then(() => {
            const message: string = "Password reset email sent!"
            this.dispatch({
                payload: {
                    open: true,
                    severity: "success",
                    message: `${message}`,
                    vertical: "top",
                    horizontal: "center",
                    variant: "outlined"
                },
                type: "alert/useAlertReducer"
            })
        })


    }

    async onAuthState() {
        
        const gitConfig: GitConfig = this.config.git()

        function gitClone(repo: GitRepoConfig) {
            const fs = repo.fs;
            const dir = repo.dir
            const url = repo.url
            const http = gitConfig.http
            const author = gitConfig.author
            const ref = gitConfig.ref
            const corsProxy = gitConfig.corsProxy
            git.listFiles({ fs, dir, ref }).then((listFIles) => {
                if (listFIles) {
                    git.pull({
                        fs,
                        http,
                        dir,
                        author
                    }).then(() => {
                        git.checkout({ fs, dir, ref: `${process.env.NODE_ENV}` }).then(() => {
                            console.log("git checkout on auth: ", `${process.env.NODE_ENV}`);

                        }).catch(err => {
                            console.log("git checkout on auth err: ", err);
                            git.currentBranch({
                                fs,
                                dir,
                                fullname: false
                            }).then((branchs: any) => {
                                if (!branchs.includes(`${process.env.NODE_ENV}`)) {
                                    git.branch({ fs, dir, ref: `${process.env.NODE_ENV}` }).then(() => {
                                        git.push({ fs, http, dir, ref: `${process.env.NODE_ENV}` }).then(() => {
                                            console.log("new branch: ", `${process.env.NODE_ENV}`);

                                        })
                                    })
                                }
                            })

                        })
                    })
                }
            }).catch((err) => {
                git.clone({ fs, http, dir, corsProxy, url, singleBranch: false, depth: 1 }).then(() => {
                    git.checkout({ fs, dir, ref: `${process.env.NODE_ENV}` }).then(() => {
                        console.log("git checkout on auth: ", `${process.env.NODE_ENV}`);

                    }).catch(err => {
                        console.log("git checkout on auth err: ", err);
                        git.branch({ fs, dir, ref: `${process.env.NODE_ENV}` }).then(() => {
                            git.currentBranch({
                                fs,
                                dir,
                                fullname: false
                            }).then((branchs: any) => {
                                console.log(branchs);
                                
                                if (!branchs.includes(`${process.env.NODE_ENV}`)) {
                                    git.branch({ fs, dir, ref: `${process.env.NODE_ENV}` }).then(() => {
                                        // git.push({ fs, http, dir, ref: `${process.env.NODE_ENV}` }).then(() => {
                                        //     console.log("new branch: ", `${process.env.NODE_ENV}`);

                                        // })
                                    })
                                }
                            })
                        })
                    })
                })
            })
        }
        try {
            const firebase = await this.service.firebase()
            const serviceData: any = await firebase.auth().onAuthStateChanged()
            console.log(serviceData);
            
            if (serviceData) {
                this.dispatch({
                    payload: serviceData,
                    type: "user/useUserReducer"
                })
                this.dispatch({ type: "login/useLoginReducer" })
                console.log(serviceData.uid);

                const userRepo = gitConfig.org('idev-coder-console').repo(serviceData.uid)
                gitClone(userRepo)
            } else {
                this.dispatch({ type: "login/useLogoutReducer" })
                this.localStorage.clear()
            }
        } catch (err) {
            this.dispatch({
                payload: {
                    open: true,
                    severity: "error",
                    message: `${err}`,
                    vertical: "top",
                    horizontal: "center",
                    variant: "outlined"
                },
                type: "alert/useAlertReducer"
            })
        }
    }
}
