import styled from "@emotion/styled";
import { Chip } from "@mui/material";
import { emphasize } from "@mui/system";

export const StyledBreadcrumb = styled(Chip)((val: any) => {
    const backgroundColor =
        val.theme.palette.mode === 'light'
            ? val.theme.palette.grey[100]
            : val.theme.palette.grey[800];
    return {
        backgroundColor,
        height: val.theme.spacing(3),
        color: val.theme.palette.text.primary,
        fontWeight: val.theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: val.theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
}) as typeof Chip; // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591
