import React from 'react';
import './App.css';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import LoginPage from './pages/login';
import RegisterPage from './pages/register';
import { AppDispatch, RootState } from './shared/reducers/store';
import { useDispatch, useSelector } from 'react-redux';
import HomePage from './pages/home';
import { Logic } from './shared/logic';
import { useLogic } from './providers/LogicProvider';
import TrackPage from './components/TrackPage';

function Layout() {


  return (<div
    style={{
      height: "100vh",
    }}
  >
    <TrackPage></TrackPage>
    <Outlet />
  </div>);

}

function App(props: any) {
  const login = useSelector((state: RootState) => state.login.value);
  const { setup } = useLogic()
  const dispatch: AppDispatch = useDispatch();

  const navigate = useNavigate();



  async function init() {
    const secret = await props.config.secret.get()
    const key = await props.config.key.get()
    const iv = await props.config.iv.get()
    setup({
      dispatch: dispatch, config: {
        secret: secret,
        key: key,
        iv: iv
      }
    }, (logic: Logic) => {

      logic.auth().onAuthState()
    })

  }

  React.useEffect(() => {
    init()
  }, [])

  React.useEffect(() => {
    if (login) {
      navigate(`/`, { replace: true })
    }
  }, [])


  return (
    <Routes>

      <Route element={<Layout />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
      </Route>
    </Routes>
  );
}

export default App;
