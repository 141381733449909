import { decryptHex } from "../shared/libs/crypto-utils";
import http from 'isomorphic-git/http/web'
import LightningFS from '@isomorphic-git/lightning-fs'
import { FirebaseApp, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { Pipeline } from "../shared/libs/pipeline";

export class Config {
    secret: string;
    key: string;
    iv: string;
    constructor({ secret, key, iv }: {
        secret: string,
        key: string,
        iv: string,
    }) {
        this.iv = iv
        this.key = key
        this.secret = secret
    }

    github() {
        const githubConfig = new GitHubConfig({
            secret: this.secret,
            key: this.key,
            iv: this.iv,
        })
        return githubConfig
    }
    git() {
        const accessToken = this.github().secret
        return new GitConfig(accessToken)
    }
    async firebase() {
        const pipeline = new Pipeline()
        const pipelineValue = await pipeline.get()
        

        const { apiKey, authDomain, projectId, storageBucket, messagingSenderId, appId, measurementId } = pipelineValue.config.environment.firebaseConfig
        const firebaseConfig = {
            apiKey: apiKey,
            authDomain: authDomain,
            projectId: projectId,
            storageBucket: storageBucket,
            messagingSenderId: messagingSenderId,
            appId: appId,
            measurementId: measurementId
        };
        return new FirebaseConfig(firebaseConfig)
    }
}

export class FirebaseConfig {
    app: FirebaseApp
    constructor(config: {
        apiKey: string,
        authDomain: string,
        projectId: string,
        storageBucket: string,
        messagingSenderId: string,
        appId: string,
        measurementId: string
    }) {
        this.app = initializeApp(config)
    }

    auth() {
        return getAuth(this.app)
    }
    analytics() {
        return getAnalytics(this.app)
    }
}

export class GitHubConfig {
    secret: string;
    key: string;
    iv: string;
    constructor({ secret, key, iv }: {
        secret: string,
        key: string,
        iv: string,
    }) {
        const githubIvdecrypted = decryptHex(secret, key, iv)
        this.iv = iv
        this.key = key
        this.secret = githubIvdecrypted

    }


}

export class GitConfig {
    accessToken: string
    http: any = http
    corsProxy: string = 'https://cors.isomorphic-git.org'
    ref: string = 'HEAD'
    author: { name: string, email: string } = {
        name: 'idev-coder',
        email: 'idevcoder7@gmail.com'
    }
    constructor(accessToken: string) {
        this.accessToken = accessToken
    }

    org(name: string) {

        return new GitOrgConfig(this.accessToken, name)
    }
}

export class GitOrgConfig {
    accessToken: string
    org: string
    constructor(accessToken: string, org: string) {
        this.accessToken = accessToken;
        this.org = org
    }

    repo(name: string) {
        return new GitRepoConfig(this.accessToken, this.org, name)
    }
}

export class GitRepoConfig {
    accessToken: string
    org: string
    dir: string = '/'
    fs: LightningFS
    url: any
    constructor(accessToken: string, org: string, repo: string) {
        
        this.accessToken = accessToken;
        this.org = org
        this.fs = new LightningFS(`${repo}`)
        this.url = `https://${this.accessToken}@github.com/${this.org}/${repo}.git`
    }

}

