import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import { Button } from '@mui/material';

export default function ActionAreaCard(props: any) {
    return (
        <Card sx={{ width: 250 }} component={Button} href={props.link} >
            <CardActionArea>
                <div style={{
                    height: '100px',
                    width: '100%',
                    fontSize: '60px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'rgba(53, 86, 148, 0.45)'

                }}>
                    {props.icon.type === "awesome" && (<i className={`fa-duotone fa-solid fa-${props.icon.value}`}></i>)}
                </div>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div" style={{ textAlign: 'center' }}>
                        {props.appName}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {props.discription}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
