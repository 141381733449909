import { Box, Button, Divider, Grid, IconButton, InputAdornment, Paper, TextField, Typography, useTheme } from "@mui/material";
import React from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
// import singInService from "../../shared/services/auth/email/signin.service";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../shared/reducers/store";
import { useLogic } from "../../providers/LogicProvider";
import Background from "../../components/Backgroud";
import DenseAppBar from "../../components/DenseAppBar";
import { GoogleAuthProvider } from "firebase/auth";
// import firebase from "firebase";
import 'firebase/compat/auth';

export default function RegisterPage() {
    const user = useSelector((state: RootState) => state.user.value);
    const login = useSelector((state: RootState) => state.login.value);

    const dispatch: AppDispatch = useDispatch();
    // const registerLogic = new RegisterLogic(dispatch)
    const { logic } = useLogic()


    const theme = useTheme();
    let navigate = useNavigate();
    let location = useLocation();
    const [config, setConfig] = React.useState<any>({
        firebaseAuth: null,
        uiConfig: null
    })

    const [showPassword, setShowPassword] = React.useState(false);
    let from = location.state?.from?.pathname || `/login`;

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        let formData = new FormData(event.currentTarget);
        let email = formData.get("email") as string;
        let password = formData.get("password") as string;



        logic?.auth().register({ email, password }).then((res: any) => {
            navigate(from, { replace: true });
        })

    }

    async function init() {
        const firebaseConfig = await logic?.config.firebase()
        const uiConfig: any = {
            // Popup signin flow rather than redirect flow.
            signInFlow: 'popup',
            // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
            signInSuccessUrl: '/signedIn',
            // We will display Google and Facebook as auth providers.
            signInOptions: [
                GoogleAuthProvider.PROVIDER_ID
            ],
        } as const;

        setConfig({
            firebaseAuth: firebaseConfig,
            uiConfig: uiConfig
        })
    }

    React.useEffect(() => {
        init()
    }, [])

    if (login) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to={`/`} state={{ from: location }} replace />;
    }
    return (
        <Background>
            <DenseAppBar position="fixed"></DenseAppBar>
            <div style={{
                zIndex: 1,
                position: 'relative',
            }}>
                <div style={{
                    height: '100vh',
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <Paper
                        sx={{
                            padding: '50px',
                            backgroundColor: 'white',
                        }}
                        elevation={0}
                    >
                        <div style={{
                            justifyContent: 'center',
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <img src="/icon-b.svg" style={{
                                width: '150px',
                                height: '150px'
                            }} />
                        </div>
                        <div style={{
                            justifyContent: 'center',
                            display: 'flex',
                            alignItems: 'center',
                        }}>

                            <Typography variant='h5' component={"h5"}>สมัครสมาชิก</Typography>
                        </div>
                        {/* {config.firebaseAuth && config.uiConfig && (<div>

                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>

                                <FirebaseAuthUi firebaseAuth={config.firebaseAuth} uiConfig={config.uiConfig} />

                            </div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <Divider sx={{ width: "100px" }} ></Divider><span style={{
                                    padding: "10px"
                                }}>หรือ</span><Divider sx={{ width: "100px" }}></Divider>
                            </div>
                        </div>)} */}
                        <div style={{
                            justifyContent: 'center',
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                                }}
                                noValidate
                                autoComplete="off"
                                onSubmit={handleSubmit}
                            >
                                <Grid container spacing={2} sx={{ display: 'contents' }}>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="อีเมล"
                                            id="email"
                                            name="email"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"><i className="fa-duotone fa-user"></i></InputAdornment>,
                                            }}
                                            size="small"
                                        />

                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="รหัสผ่าน"
                                            id="password"
                                            name="password"
                                            type={showPassword ? "text" : 'password'}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <i className="fa-duotone fa-eye-slash"></i> : <i className="fa-duotone fa-eye"></i>}
                                                    </IconButton>
                                                </InputAdornment>,
                                            }}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{
                                        justifyContent: 'center',
                                        display: 'flex',
                                    }}>
                                        <Button sx={{
                                            width: '175px'
                                        }} type='submit' variant="outlined" >ลงทะเบียน</Button>
                                    </Grid>
                                </Grid>
                            </Box>

                        </div>

                    </Paper>
                </div>
            </div>
        </Background>)
}