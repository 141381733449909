import * as React from 'react';
import './bg.style.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../shared/reducers/store';
import { useLocation } from 'react-router-dom';

export default function Background(props: any) {
    const login = useSelector((state: RootState) => state.login.value);
    const location = useLocation();
    
    function mode() {
        if (login) {
            return 'private'
        } else {
            if (location.pathname.includes("login")) {
                return "login"
            } else if (location.pathname.includes("register")) {
                return "register"
            } else {
                return "private"
            }
        }
    }

    return (
        <React.Fragment>
            <div className={`${mode()}-area`} >
                {props.children}
                <ul className={`${mode()}-circles`}>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div >
        </React.Fragment>)
}