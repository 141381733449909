import * as React from 'react';
import Background from '../../components/Backgroud';
import DenseAppBar from '../../components/DenseAppBar';
import { useSelector } from 'react-redux';
import { RootState } from '../../shared/reducers/store';
import PublicPage from '../public';
import PrivatePage from '../private';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLogic } from '../../providers/LogicProvider';
import LoginPage from '../login';

export default function HomePage() {
    const login = useSelector((state: RootState) => state?.login.value);
    const [searchParams] = useSearchParams();
    let navigate = useNavigate();
    const { logic } = useLogic()

    async function init() {
        const queryToken = searchParams.get("token")


        if (queryToken) {
            const data = await logic?.token().read(queryToken)
            console.log(data);

            if (data) {
                logic?.auth().loginToken(data[0].value.stsTokenManager.accessToken)
                navigate('/', { replace: true })
            }
        } else {
            logic?.auth().onAuthState()
        }
    }
    React.useEffect(() => {
        init()
    }, [logic])


    return (<>
        <Background>
            <DenseAppBar position="fixed"></DenseAppBar>
            <div style={{
                zIndex: 1,
                position: 'relative',
            }}>
                {login ? (<PrivatePage></PrivatePage>) : (<LoginPage></LoginPage>)}
            </div>
        </Background>
    </>)
}