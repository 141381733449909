import git from 'isomorphic-git'
import LightningFS from '@isomorphic-git/lightning-fs'
import http from 'isomorphic-git/http/web'

export class Key {
    uri: string = 'https://gist.github.com'
    dir: string = '/'
    fs = new LightningFS('key')
    http: any = http
    corsProxy: string = 'https://cors.isomorphic-git.org'
    ref: string = 'HEAD'
    author: { name: string, email: string } = {
        name: 'idev-coder',
        email: 'idevcoder7@gmail.com'
    }

    init() {
        const fs = this.fs
        const dir = this.dir
        const ref = this.ref
        const author = this.author
        const http = this.http
        const corsProxy = this.corsProxy
        git.listFiles({ fs, dir: dir, ref }).then((listFIles) => {
            if (listFIles) {
                git.pull({
                    fs,
                    http,
                    dir,
                    author
                })
            }
        }).catch((err) => {
            if (process.env.NODE_ENV === 'development') {
                let url = `${this.uri}/9668ab828275daea9acae350110fb055.git`
                git.clone({ fs, http, dir, corsProxy, url, singleBranch: false, depth: 1 }).then(() =>{
                    // window.location.reload()
                })
            } else if (process.env.NODE_ENV === 'production') {
                let url = `${this.uri}/4fc1c8a93128302c9d6cf7b0a31d23ff.git`
                git.clone({ fs, http, dir, corsProxy, url, singleBranch: false, depth: 1 }).then(() =>{
                    // window.location.reload()
                })
            }

        })


    }

    async get() {
        const file = await this.fs.promises.readFile('/key.txt', 'utf8')
        return file


    }
}