import axios, { AxiosInstance } from "axios"
import { Config, FirebaseConfig } from "../../config"
import { AuthProvider, createUserWithEmailAndPassword, onAuthStateChanged,  sendEmailVerification, sendPasswordResetEmail, signInWithCustomToken, signInWithEmailAndPassword, signInWithPopup, signOut, User } from "firebase/auth"

export class Service {
    config: Config
    accessToken: string
    constructor(config: Config) {
        this.config = config
        this.accessToken = config.github().secret
    }

    async firebase() {
        const firebaseConfig = await this.config.firebase()
        
        return new FirebaseService(firebaseConfig)
    }

    github() {
        return new GithubService(this.accessToken)
    }
}

export class FirebaseService {
    config: FirebaseConfig
    constructor(config: FirebaseConfig) {
        this.config = config
    }

    auth() {
        return new FirebaseAuthService(this.config)
    }
}

export class FirebaseAuthService {
    config: FirebaseConfig
    constructor(config: FirebaseConfig) {
        this.config = config
    }

    onAuthStateChanged() {
        return new Promise((res, rej) => {
            console.log(this.config.auth());
            
            onAuthStateChanged(this.config.auth(), (user) => {
                if (user) {
                    // User is signed in, see docs for a list of available properties
                    // https://firebase.google.com/docs/reference/js/auth.user


                    res(user)
                    // ...
                } else {
                    // User is signed out
                    // ...

                    res(null)
                }

            }, (error) => {
                rej(error)
            })
        })
    }

    signOut() {
        return signOut(this.config.auth())
    }

    token(token:string) {
        return signInWithCustomToken(this.config.auth(),token)
    }

    email() {
        return new FirebaseAuthEmailService(this.config)
    }

    google() {
        return new FirebaseAuthGoogleService(this.config)
    }
}

export class FirebaseAuthGoogleService {
    config: FirebaseConfig
    constructor(config: FirebaseConfig) {
        this.config = config
    }

    signIn(provider: AuthProvider) {
        return signInWithPopup(this.config.auth(), provider)
    }


}

export class FirebaseAuthEmailService {
    config: FirebaseConfig
    constructor(config: FirebaseConfig) {
        this.config = config
    }

    signUp({ email, password }: { email: string, password: string }) {
        return createUserWithEmailAndPassword(this.config.auth(), email, password)
    }

    signIn({ email, password }: { email: string, password: string }) {

        return signInWithEmailAndPassword(this.config.auth(), email, password)
    }

    resetPassword(email: string) {
        return sendPasswordResetEmail(this.config.auth(), email)
    }
    emailVerification(user: User) {
        return sendEmailVerification(user)
    }
}


export class GithubService {
    accessToken: string
    http: AxiosInstance
    constructor(accessToken: string) {
        this.accessToken = accessToken
        this.http = axios.create({
            baseURL: "https://api.github.com",
            headers: {
                'Accept': 'application/vnd.github+json',
                'Authorization': `Bearer ${accessToken}`,
                'X-GitHub-Api-Version': '2022-11-28'
            }
        });
    }

    org(name: string) {
        return new GithubOrgService({ http: this.http, accessToken: this.accessToken, org: name })
    }
}

export class GithubOrgService {
    accessToken: string
    http: AxiosInstance
    org: string
    constructor({ http, accessToken, org }: { http: AxiosInstance, accessToken: string, org: string }) {
        this.accessToken = accessToken
        this.http = http
        this.org = org
    }

    repo(name: string) {
        return new GithubRepoService({
            http: this.http,
            accessToken: this.accessToken,
            org: this.org,
            repo: name
        })
    }
}

export class GithubRepoService {
    accessToken: string
    http: AxiosInstance
    org: string
    repo: string
    constructor({ http, accessToken, org, repo }: { http: AxiosInstance, accessToken: string, org: string, repo: string }) {
        this.accessToken = accessToken
        this.http = http
        this.org = org
        this.repo = repo
    }

    create() {
        return this.http.post(`/orgs/${this.org}/repos`, {
            name: this.repo,
            description: `User ID ${this.repo}`,
            homepage: `https://github.com/${this.org}/${this.repo}`,
            private: true,
            has_issues: false,
            has_projects: false,
            has_wiki: false
        })
    }
}